import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react'
import fetchSaveFile from '../utils/fetchSaveFile'
import saveFile from '../utils/saveFile'
import Button from './Button'
import { useLayoutContext } from './Layout'
import { LayoutProps } from './Layout.context'
import RepairOrderCard from './RepairOrderCard'
import RepairOrderContainer from './RepairOrderContainer'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { repairSummaryPageStepLabel } from '../templates/RepairSummaryPage.context'
import base64toBlob from '../utils/base64toBlob'
import RepairOrderRecover from './RepairOrderRecover'
import { servicesLinks } from '../data/menu'
import { IRepairSummary, QuoteParams } from '../templates/RepairSummaryPage'
import { PRICING_OFFER_RECOVER_REFERENCE } from '../data/pricingOfferRecoverReference'
import { useLocation } from './Layout'
import isServerSideRendering from '../utils/isServerSideRendering'

/**
 * Add support for virtual url suffix through browser history management.
 * See pages/404.tsx for redirection of virtual page
 */
const useVirtualUrlSuffix = (
  suffix: string,
  layoutPropsComputed: LayoutProps,
  onBack: () => void
) => {
  const location = useLocation()
  const [links, setLinks] = useState<Element[]>([])
  const requestRef = useRef<number | undefined>()

  const setupLinks = useCallback(() => {
    const links = document.querySelectorAll(`a[href='${location?.pathname}']`)
    setLinks(Array.from(links))
  }, [location])

  useEffect(() => {
    if (!isServerSideRendering()) {
      requestRef.current = requestAnimationFrame(setupLinks)
      return () => {
        if (requestRef.current !== undefined) {
          cancelAnimationFrame(requestRef.current)
        }
      }
    }
    return
  }, [layoutPropsComputed])

  useEffect(() => {
    const listener = (e: Event) => {
      e.preventDefault()
      onBack()
      history.back()
    }
    links.forEach((x) => x.addEventListener('click', listener))
    return () => {
      links.forEach((x) => x.removeEventListener('click', listener))
    }
  }, [links, onBack])

  useEffect(() => {
    if (!isServerSideRendering()) {
      history.pushState(null, '', `${location?.href}${suffix}`)
    }
  }, [location, onBack, suffix])

  useEffect(() => {
    if (!isServerSideRendering()) {
      const listener = () => {
        onBack()
      }
      window.addEventListener('popstate', listener)
      return () => {
        window.removeEventListener('popstate', listener)
      }
    }
    return
  }, [onBack])
}

interface RepairSummaryOrderProps {
  layoutProps: LayoutProps
  onBack: () => void
  quoteReference: string
  data: IRepairSummary
  quoteParams: QuoteParams
}

const RepairSummaryOrder: React.FC<RepairSummaryOrderProps> = ({
  layoutProps,
  onBack,
  quoteReference,
  quoteParams,
  data,
}) => {
  const location = useLocation()

  const layoutPropsComputed: LayoutProps = useMemo(() => {
    const props: LayoutProps = {
      repairStepsContainerProps: {
        showHero: false,
      },
      repairStepsProps: {
        ...layoutProps.repairStepsProps,
        step3: [{ label: repairSummaryPageStepLabel, url: location?.pathname }],
      },
    }

    return merge(cloneDeep(layoutProps), props)
  }, [layoutProps, location])

  useLayoutContext(layoutPropsComputed)

  useVirtualUrlSuffix('validation/', layoutPropsComputed, onBack)

  const handleDownload = useCallback(async () => {
    const data = await fetchSaveFile(
      `/website/v1/quote/print/${quoteReference}`
    )
    const text = await data.text()
    const dataBase64 = text.replace(/^"/, '').replace(/"$/, '')
    const blob = await base64toBlob(dataBase64)
    const filename = `${quoteReference}.pdf`
    saveFile(blob, filename)
  }, [quoteReference])

  return (
    <RepairOrderContainer>
      <RepairOrderCard>
        <RepairOrderCard.Title>
          Merci d'avoir édité votre bon de réparation sur notre site !
        </RepairOrderCard.Title>
        <RepairOrderCard.Description>
          N'oubliez pas de le présenter dès votre arrivée en magasin pour
          bénéficier de sa réduction. Vous l'avez reçu par mail, vous pouvez
          l'imprimer en cliquant sur "imprimer mon bon en PDF"
        </RepairOrderCard.Description>
        <RepairOrderCard.Buttons>
          <Button onClick={handleDownload}>Imprimer mon bon en PDF</Button>
          <Button.Link to="/" variant="outline">
            Accueil
          </Button.Link>
        </RepairOrderCard.Buttons>
      </RepairOrderCard>

      {!quoteParams.pricingOffers.includes(PRICING_OFFER_RECOVER_REFERENCE) &&
        !(data.price instanceof Error) &&
        data.price?.webPricingOffer?.promoActivated &&
        data.price?.webPricingOffer?.promoReference ===
          PRICING_OFFER_RECOVER_REFERENCE && (
          <RepairOrderRecover to={servicesLinks.recover}>
            <RepairOrderRecover.Title>
              Et c’est pas fini
            </RepairOrderRecover.Title>
            <RepairOrderRecover.Description
              dangerouslySetInnerHTML={{
                __html: `
              ${data.price?.webPricingOffer.promoTitle}
              ${data.price?.webPricingOffer.promoDescription}
            `,
              }}
            ></RepairOrderRecover.Description>
            <RepairOrderRecover.Price
              value={data.price?.webPricingOffer.promoAmount ?? 0}
            />
          </RepairOrderRecover>
        )}
    </RepairOrderContainer>
  )
}

export default RepairSummaryOrder

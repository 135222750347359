import styled from 'styled-components'
import LinkExtended from './LinkExtended'

const ReparationExternalBrand = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  font-family: Roboto, sans-serif;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 20px 0 0 0;
    row-gap: 60px;
  }
`
const ReparationContainer = styled.div`
  padding: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0;
    padding: 0 20px;
  }
`
const HalfGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 40px;
  padding: 26px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: 1fr;
    column-gap: 10%;
    & > div:nth-child(2) {
      grid-row: auto !important;
    }
  }

  & > div:nth-child(2) {
    grid-row: 1 / 2;
    grid-row-end: none;
  }
`

const Button = styled(LinkExtended)<ExternalBrandProp>`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  width: fit-content;
  padding: 12px 24px;
  cursor: pointer;
  -webkit-appearance: none; // fix iOS display
  transition: all 300ms ease;
  text-decoration: none !important;

  &:hover {
    border: 1px solid ${({ color }) => color} !important;
    color: ${({ color }) => color} !important;
    background: none !important;
  }
`
interface ExternalBrandProp {
  color?: string
  mobileColor?: string
}

const H1Colored = styled.h1<ExternalBrandProp>`
  color: #333;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
  & > p {
    color: ${({ color }) => color};
    display: inline;
  }
`

const H2Colored = styled.h1<ExternalBrandProp>`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  & > p {
    color: ${({ color }) => color};
    display: inline;
  }
`

const DescriptionContainer = styled.div<ExternalBrandProp>`
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  border-radius: 10px;
  padding: 26px;
  background: ${({ mobileColor }) => mobileColor ?? 'none'};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background: ${({ color }) => color ?? 'none'};
    padding: 0;
  }
`

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  & > b {
    font-weight: 700;
  }
`

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`

const ImgContainer = styled.div`
  padding: 0 26px;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0;
  }
`

const CardContainer = styled.div`
  border-radius: 10px;
  max-width: 273px;
  max-height: 279px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.secondary};

  & > p {
    color: white;
    background: ${({ theme }) => theme.colors.secondary};
    text-align: center;
    padding: 6px;
    font-size: 18px;
    font-weight: 600;
  }
`

const BrandProcessContainer = styled.div`
  border: none;
  border-radius: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 26px 60px 30px;
  row-gap: 40px;
  background: #ffff;
  margin-bottom: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 40px;
    border-radius: 10px;
    row-gap: 50px;
  }
`

const BrandAdvantageContainer = styled.div<ExternalBrandProp>`
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 40px;
  padding: 0 26px;
  background: ${({ color }) => color ?? 'none'};
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 40px;
    row-gap: 50px;
  }
`
const AdvantageAbsoluteSpan = styled.span`
  position: relative;
  width: 100%;
  height: 120px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 100%;
  }
  
  & > span {
    position: absolute;
    top: 50%;
    left: 25%;
    color: #fff;
    width: fit-content;
    height: fit-content;
    font-size: 95px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      top: 23%;
      left: 28%;
    }
  }
`

const AdvantagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 40px;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    row-gap: 46px;
    padding: 0;
  }
`
const AdvantageBodyContainer = styled.div`
  display: grid;
  grid-template-columns: 78px 4fr;
  column-gap: 20px;
  align-items: center;
  justify-items: inherit;
`
const AdvantageBodyText = styled.div<ExternalBrandProp>`
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;

  & > p {
    color: ${({ color }) => color};
    display: inline;
  }
`

const InformationContainer = styled.div<ExternalBrandProp>`
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
  padding: 30px;
  margin: 0 26px;
  background: ${({ color }) => color ?? 'none'};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 40px;
    margin: 0;
  }
`

const H2WithInformation = styled.h2`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  margin-bottom: 10px;

  & > span {
    position: absolute;
    border: 1px solid rgb(15, 76, 129);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    top: -3px;
    padding: 3px;
  }

  & > p {
    margin-left: 45px;
    display: inline;
  }
`

const SecondaryButtonWithColor = styled.div<ExternalBrandProp>`
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  border-radius: 5px;
  display: inline;
  font-weight: 700;
  padding: 2px 10px;
`

const AdvantageIconDiv = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  display: flex;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  width: 78px;
  height: 78px;
  padding: 10px 0 0 0;

  & > div {
    position: absolute !important;
    margin: 7px;
  }
`

export default Object.assign(ReparationExternalBrand, {
  HalfGridContainer,
  ReparationContainer,
  Paragraph,
  Button,
  H1Colored,
  H2Colored,
  AdvantageIconDiv,
  SecondaryButtonWithColor,
  H2WithInformation,
  AdvantageBodyText,
  AdvantageBodyContainer,
  AdvantagesContainer,
  AdvantageAbsoluteSpan,
  BrandAdvantageContainer,
  BrandProcessContainer,
  CardContainer,
  CardsContainer,
  ImgContainer,
  DescriptionContainer,
  InformationContainer,
})

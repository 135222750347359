import { ExternalBrandEnum } from '../data/externalBrand'

export const getExternalBrandColor = (code: string) => {
  const status = Object.values(ExternalBrandEnum).find(
    (status) => status.code === code
  )
  return status ? status.colorCode : '#0F4C81'
}

export const getExternalBrandCampaignId = (code: string) => {
  const status = Object.values(ExternalBrandEnum).find(
    (status) => status.code === code
  )
  return status ? status.campaignId : ''
}
